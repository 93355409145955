<template>
  <div class="auth-content d-flex align-center justify-center">
    <v-card
      max-height="90%"
      :loading="loading"
      class="mx-auto pa-2"
      max-width="374"
    >
      <template slot="progress">
        <v-progress-linear
          color="deep-purple"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <v-img height="250" contain src="@/assets/logomarca.jpg"></v-img>
      <v-row align="center" justify="center">
        <v-card-title class="font-weight-bold">Login</v-card-title>
      </v-row>
      <v-text-field
        v-model="user.login"
        label="Usuario"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="user.password"
        label="Senha"
        :type="show ? 'text' : 'password'"
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="show = !show"
        outlined
      ></v-text-field>
      <v-card-actions class="justify-end">
        <v-btn class="font-weight-bold" color="primary" @click="signin">
          Entrar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-alert dismissible class="mt-3" type="error" v-if="error"> {{ textError }} </v-alert>
  </div>
</template>

<script>
import { baseApiUrl, userKey } from "@/global";
import axios from "axios";
export default {
  name: "Auth",
  data: function () {
    return {
      user: {},
      loading: false,
      show: false,
      error: false,
      textError: "",
    };
  },
  methods: {
    signin() {
      axios
        .post(`${baseApiUrl}/signin`, this.user)
        .then((res) => {
          this.$store.commit("setUser", res.data);
          localStorage.setItem(userKey, JSON.stringify(res.data));
          this.$router.push({ path: "/" });
        })
        .catch((err) => {
          this.error = true;
          this.textError = err.response.data;
        });
    },
  },
};
</script>

<style>
.auth-content {
  height: 100%;
  flex-direction: column;
}
</style>